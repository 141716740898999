exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-careers-sales-and-growth-specialist-tsx": () => import("./../../../src/pages/careers/sales-and-growth-specialist.tsx" /* webpackChunkName: "component---src-pages-careers-sales-and-growth-specialist-tsx" */),
  "component---src-pages-careers-software-developer-tsx": () => import("./../../../src/pages/careers/software-developer.tsx" /* webpackChunkName: "component---src-pages-careers-software-developer-tsx" */),
  "component---src-pages-cig-qa-tsx": () => import("./../../../src/pages/cig-qa.tsx" /* webpackChunkName: "component---src-pages-cig-qa-tsx" */),
  "component---src-pages-clinic-info-index-tsx": () => import("./../../../src/pages/clinic-info/index.tsx" /* webpackChunkName: "component---src-pages-clinic-info-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-diversity-and-inclusion-index-tsx": () => import("./../../../src/pages/diversity-and-inclusion/index.tsx" /* webpackChunkName: "component---src-pages-diversity-and-inclusion-index-tsx" */),
  "component---src-pages-feedback-index-tsx": () => import("./../../../src/pages/feedback/index.tsx" /* webpackChunkName: "component---src-pages-feedback-index-tsx" */),
  "component---src-pages-help-index-tsx": () => import("./../../../src/pages/help/index.tsx" /* webpackChunkName: "component---src-pages-help-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mika-ai-medical-scribe-index-tsx": () => import("./../../../src/pages/mika-ai-medical-scribe/index.tsx" /* webpackChunkName: "component---src-pages-mika-ai-medical-scribe-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy_policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-product-index-tsx": () => import("./../../../src/pages/product/index.tsx" /* webpackChunkName: "component---src-pages-product-index-tsx" */),
  "component---src-pages-scribe-access-index-tsx": () => import("./../../../src/pages/scribe-access/index.tsx" /* webpackChunkName: "component---src-pages-scribe-access-index-tsx" */),
  "component---src-pages-scribe-info-index-tsx": () => import("./../../../src/pages/scribe-info/index.tsx" /* webpackChunkName: "component---src-pages-scribe-info-index-tsx" */),
  "component---src-pages-scribe-signup-index-tsx": () => import("./../../../src/pages/scribe-signup/index.tsx" /* webpackChunkName: "component---src-pages-scribe-signup-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-terms-information-management-agreement-ab-tsx": () => import("./../../../src/pages/terms/information-management-agreement-ab.tsx" /* webpackChunkName: "component---src-pages-terms-information-management-agreement-ab-tsx" */),
  "component---src-pages-terms-mikata-terms-of-use-tsx": () => import("./../../../src/pages/terms/mikata-terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-mikata-terms-of-use-tsx" */),
  "component---src-pages-terms-service-level-description-tsx": () => import("./../../../src/pages/terms/service-level-description.tsx" /* webpackChunkName: "component---src-pages-terms-service-level-description-tsx" */),
  "component---src-pages-terms-support-services-description-tsx": () => import("./../../../src/pages/terms/support-services-description.tsx" /* webpackChunkName: "component---src-pages-terms-support-services-description-tsx" */),
  "component---src-pages-who-we-serve-family-medicine-tsx": () => import("./../../../src/pages/who-we-serve/family-medicine.tsx" /* webpackChunkName: "component---src-pages-who-we-serve-family-medicine-tsx" */),
  "component---src-pages-who-we-serve-index-tsx": () => import("./../../../src/pages/who-we-serve/index.tsx" /* webpackChunkName: "component---src-pages-who-we-serve-index-tsx" */),
  "component---src-pages-who-we-serve-medical-imaging-tsx": () => import("./../../../src/pages/who-we-serve/medical-imaging.tsx" /* webpackChunkName: "component---src-pages-who-we-serve-medical-imaging-tsx" */),
  "component---src-pages-who-we-serve-specialty-medicine-tsx": () => import("./../../../src/pages/who-we-serve/specialty-medicine.tsx" /* webpackChunkName: "component---src-pages-who-we-serve-specialty-medicine-tsx" */)
}

