/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react";
import Theme from "./src/components/Theme";

import "./static/globalStyles.css";

export const wrapRootElement = ({ element }) => {
  return <Theme>{element}</Theme>;
};
