/* Theme
======================================= */

import { ThemeProvider } from "styled-components";
import "what-input"; // this is for accessability

/* types
--------------------------------------- */

export interface CommonProps {
  as?: any;
  css?: any;
  className?: string;
  children?: React.ReactNode | any;
  tabIndex?: number;
  ref?: any;
}

/* theme
-------------------------------------- */

export const theme = {
  colors: {
    primary: "#003125",
    mint: "#19FFBE",
    pink: "#FF878F",
    green: "#00D598",
    blue: "#809AFF",
    yellow: "#F8C514",

    complementary: {
      pink: "#FEF0F1",
      green: "#E2FAF3",
      blue: "#E2E4FA"
    }
  }
};

export type ThemeTypes = typeof theme;
export type ColorTypes = keyof typeof theme.colors;

/* Component
---------------------------------------- */

export const Theme = ({ children }: any) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
